@font-face {
  font-family: AGaramondPro;
  src: url("AGaramondPro-Regular.0c7867be.otf") format("opentype");
}

@font-face {
  font-family: AGaramondPro;
  font-weight: bold;
  src: url("AGaramondPro-Bold.f5fa1ffc.otf") format("opentype");
}

@font-face {
  font-family: AGaramondPro;
  font-style: italic;
  src: url("AGaramondPro-Italic.851d9e6b.otf") format("opentype");
}

@font-face {
  font-family: AGaramondPro;
  font-style: italic;
  font-weight: bold;
  src: url("AGaramondPro-BoldItalic.76dcdc2e.otf") format("opentype");
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: AGaramondPro;
  font-size: 16px;
}

header {
  height: 100vh;
  background-image: url("https://static.farnostkvp.sk/malba.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.nav {
  justify-content: space-between;
  padding-top: 2rem;
  display: flex;
}

.nav a {
  color: #fff;
  text-transform: uppercase;
  font-size: .8rem;
  text-decoration: none;
}

.nav a:last-child {
  border: 1px solid #dca776;
}

.nav a:last-child:hover {
  background-color: #dca776;
  transition: all .3s ease-out;
}

.nav img {
  width: 14.6rem;
  height: auto;
}

@media screen and (max-width: 850px) {
  .nav img {
    width: 12rem;
  }
}

@media screen and (max-width: 720px) {
  .nav img {
    width: 10rem;
  }
}

@media screen and (max-width: 500px) {
  .nav-links {
    display: none;
  }
}

.nav-links a {
  margin-left: 1rem;
  padding: 1rem;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.hidden {
  display: none;
}

.btn, .btn:hover {
  background-color: #d6b38c;
}

.text-sm {
  font-size: 13px !important;
}

.input-field label {
  color: #9b8c7d !important;
}

.input-field input[type="text"]:focus + label, .input-field input[type="email"]:focus + label {
  color: #d6b38c !important;
}

.input-field input[type="text"]:focus, .input-field input[type="email"]:focus, .input-field input[type="text"].valid, .input-field input[type="email"].valid {
  border-bottom: 1px solid #d6b38c !important;
  box-shadow: 0 1px #d6b38c !important;
}

[type="radio"]:checked + span:after {
  background-color: #d6b38c !important;
  border: #d6b38c !important;
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
  background-color: #d6b38c;
  border: 2px solid #d6b38c;
}

.button-radio {
  width: 100%;
  height: 40px;
  position: relative;
  background-color: #fff !important;
}

.button-radio label, .button-radio input {
  color: #9b8c7d;
  font-family: open-sans;
  display: block;
  position: absolute;
  inset: 0;
  font-size: 15px !important;
}

.button-radio input[type="radio"] {
  opacity: .011;
  z-index: 100;
}

.button-radio input[type="radio"]:checked + label {
  color: #fff;
  background: #d6b38c;
}

.button-radio label {
  cursor: pointer;
}

.button-radio input[type="number"] {
  color: #9b8c7d;
  cursor: pointer;
  width: 189px;
  text-align: center;
  background: none;
  border: none;
  outline: none;
  margin: 12.1px auto 0;
  position: static;
}

.button-radio input[type="number"]::placeholder {
  color: #9b8c7d;
  font-family: open-sans;
}

#custom-eur:checked + label #custom-eur-input::placeholder {
  color: #fff !important;
}

#custom-eur:checked + label #custom-eur-input {
  color: #fff;
}

.button-radio label span {
  font-family: open-sans;
  position: absolute;
  top: 2px;
  right: 20px;
}

.payment-cards {
  flex-wrap: wrap;
  margin-left: 10px;
  display: flex;
}

.card-logo {
  width: 35px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 5px;
}

.card-visa {
  background-image: url("https://www.tatrabanka.sk/web/images/logo_visa.gif");
}

.card-electron {
  background-image: url("https://www.tatrabanka.sk/web/images/logo_visa_electron.gif");
}

.card-mc {
  background-image: url("https://www.tatrabanka.sk/web/images/logo_mc.gif");
}

.card-dc {
  background-image: url("https://www.tatrabanka.sk/web/images/logo_DC.jpg");
}

.card-maestro {
  background-image: url("https://www.tatrabanka.sk/web/images/logo_maestro.gif");
}

.card-discover {
  background-image: url("https://www.tatrabanka.sk/web/images/logo_discover.jpg");
}

.submit-button {
  width: 250px;
  margin: 0 auto;
  font-size: 16px;
  display: block;
}

.main-heading-wrapper {
  color: #fff;
  text-align: center;
  background-color: #0000004d;
  margin: calc(50vh - 13rem) auto 0;
  padding: 2rem 0;
  position: relative;
}

.main-heading-wrapper h1 {
  margin: 0;
  font-size: 2.27rem;
}

@media screen and (max-width: 510px) {
  .main-heading-wrapper h1 {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 410px) {
  .main-heading-wrapper h1 {
    font-size: 1.4rem;
  }
}

.main-heading-wrapper h3 {
  text-transform: uppercase;
  margin: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: .8rem;
  font-weight: 100;
}

@media screen and (max-width: 410px) {
  .main-heading-wrapper h3 {
    font-size: .8rem;
  }
}

.Jesus-img {
  height: 35rem;
  position: absolute;
  top: -250%;
  right: 15%;
}

@media screen and (max-width: 1399px) {
  .main-heading-wrapper {
    z-index: 50;
  }
}

@media screen and (max-width: 869px) {
  .Jesus-img {
    height: 30rem;
  }
}

@media screen and (max-width: 730px) {
  .Jesus-img {
    height: 25rem;
    top: -190%;
    right: 5%;
  }
}

@media screen and (min-width: 1840px) {
  .Jesus-img {
    right: 20%;
  }
}

@media screen and (min-width: 2150px) {
  .Jesus-img {
    right: 26%;
  }
}

.about-project {
  background-color: #f2ebe2;
  padding: 3rem 0;
}

.about-project h4 {
  text-align: center;
  color: #9b8c7d;
  margin: 0;
  font-size: 1.466rem;
  font-style: italic;
}

.about-project h2 {
  text-align: center;
  color: #a36d40;
  margin: 0;
  font-size: 2.8rem;
}

.about-project p {
  text-align: center;
  color: #9b8c7d !important;
}

.small-container {
  width: 90%;
  max-width: 53rem;
  margin: 0 auto;
}

.project-description {
  align-items: center;
  margin-bottom: 5rem;
  display: flex;
}

.project-description .video-wrapper {
  padding: 0 1rem;
}

@media screen and (max-width: 799px) {
  .project-description {
    flex-direction: column-reverse;
    margin-bottom: 1rem;
  }

  .project-description .video-wrapper {
    width: 100%;
    padding: 1rem 0;
  }
}

.project-description > div {
  flex: 33.33%;
}

.project-description p {
  text-align: left;
}

.timeline {
  flex-direction: column;
  padding-left: 1rem;
  display: flex;
}

.timeline-item {
  align-items: center;
  display: flex;
}

.timeline-item h5 {
  color: #a36d40;
  text-align: center;
  margin-top: 0;
  font-size: 1.2rem;
  font-style: italic;
}

.timeline-item h4 {
  text-align: center;
  font-size: 1.466rem;
  font-style: normal;
}

.timeline-item > div {
  width: 85.72px;
  border-right: 2px solid #dcd3c9;
  padding: 1rem 1rem 1rem 0;
}

.timeline-item > p {
  flex: 3;
  padding-left: 1rem;
}

@media screen and (max-width: 799px) {
  .timeline {
    padding: 0;
  }

  .timeline-item > div {
    flex: 1;
    padding: 0;
  }

  .timeline-item > p {
    flex: 3;
    padding-left: 1rem;
  }
}

.painting-description {
  display: flex;
}

.painting-description > img {
  min-width: 535px;
  flex: 3;
  margin: 0 1rem;
}

.painting-description > div {
  flex: 2;
}

.painting-description h5, .saints-description h5, .mobile-description h5 {
  color: #a36d40;
  margin: 0;
  font-size: 1.07rem;
}

.painting-description p, .saints-description p, .mobile-description p {
  margin: 0;
  font-size: .94rem;
  color: #9b8c7d !important;
}

.pointer {
  height: 10rem;
}

.pointer-right {
  margin-right: 1rem;
  transform: rotate(180deg);
}

.pointer-left {
  margin-left: 1rem;
}

.pointer-down {
  margin: -2rem auto;
  display: block;
  transform: rotate(90deg);
}

.pointer-up {
  margin: -2rem auto;
  display: block;
  transform: rotate(-90deg);
}

.description-item {
  align-items: center;
  display: flex;
}

.painting-description .description-item {
  height: 20rem;
}

.saints-description {
  padding-bottom: 5rem;
  display: flex;
}

.saints-description > img {
  min-width: 20px;
  flex: 2;
}

.saints-description > div {
  flex: 3;
  margin: 0 1rem;
}

@media screen and (max-width: 1399px) {
  .painting-description, .saints-description {
    display: none;
  }
}

.mobile-description {
  text-align: center;
  max-width: 535px;
  margin: 3rem auto;
}

@media screen and (min-width: 1400px) {
  .mobile-description {
    display: none;
  }
}

.form-section {
  background-color: #f2ebe2;
  padding: 4rem 0;
}

.form-section h1 {
  color: #a36d40;
  text-align: center;
  margin: 0;
  font-size: 1.733rem;
}

.form-section h2 {
  color: #9b8c7d;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.6rem;
  font-style: italic;
}

.form-section p {
  text-align: center;
  max-width: 30rem;
  margin: 3rem auto;
  font-size: .94rem;
  color: #9b8c7d !important;
}

/*# sourceMappingURL=index.9be49c30.css.map */
