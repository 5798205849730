@font-face {
  font-family: AGaramondPro;
  src: url("fonts/AGaramondPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: AGaramondPro;
  font-weight: bold;
  src: url("fonts/AGaramondPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: AGaramondPro;
  font-style: italic;
  src: url("fonts/AGaramondPro-Italic.otf") format("opentype");
}

@font-face {
  font-family: AGaramondPro;
  font-style: italic;
  font-weight: bold;
  src: url("fonts/AGaramondPro-BoldItalic.otf") format("opentype");
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "AGaramondPro";
  font-size: 16px;
}

header {
  background-image: url("https://static.farnostkvp.sk/malba.jpg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh;
}

.nav {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}

.nav a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.nav a:last-child {
  border: 1px solid #dca776;
}

.nav a:last-child:hover {
  background-color: #dca776;
  transition: all ease-out 0.3s;
}

.nav img {
  width: 14.6rem;
  height: auto;
}

@media screen and (max-width: 850px) {
  .nav img {
    width: 12rem;
  }
}

@media screen and (max-width: 720px) {
  .nav img {
    width: 10rem;
  }
}

@media screen and (max-width: 500px) {
  .nav-links {
    display: none;
  }
}

.nav-links a {
  padding: 1rem;
  margin-left: 1rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.hidden {
  display: none;
}

.btn,
.btn:hover {
  background-color: #d6b38c;
}

.text-sm {
  font-size: 13px !important;
}

/* label color */
.input-field label {
  color: #9b8c7d !important;
}
/* label focus color */
.input-field input[type="text"]:focus + label,
.input-field input[type="email"]:focus + label {
  color: #d6b38c !important;
}
/* label underline focus color */
.input-field input[type="text"]:focus,
.input-field input[type="email"]:focus {
  border-bottom: 1px solid #d6b38c !important;
  box-shadow: 0 1px 0 0 #d6b38c !important;
}
/* valid color */
.input-field input[type="text"].valid,
.input-field input[type="email"].valid {
  border-bottom: 1px solid #d6b38c !important;
  box-shadow: 0 1px 0 0 #d6b38c !important;
}

[type="radio"]:checked + span:after {
  background-color: #d6b38c !important;
  border: #d6b38c !important;
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
  border: 2px solid #d6b38c;
  background-color: #d6b38c;
}

.button-radio {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: white !important;
}

.button-radio label,
.button-radio input {
  display: block;
  position: absolute;
  color: #9b8c7d;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 15px !important;
  font-family: open-sans;
}

.button-radio input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.button-radio input[type="radio"]:checked + label {
  background: #d6b38c;
  color: white;
}

.button-radio label {
  cursor: pointer;
}

.button-radio input[type="number"] {
  position: static;
  background: none;
  border: none;
  outline: none;
  color: #9b8c7d;
  cursor: pointer;
  margin: 12.1px auto 0;
  width: 189px;
  text-align: center;
}

.button-radio input[type="number"]::placeholder {
  color: #9b8c7d;
  font-family: open-sans;
}

#custom-eur:checked + label #custom-eur-input::placeholder {
  color: white !important;
}

#custom-eur:checked + label #custom-eur-input {
  color: white;
}

.button-radio label span {
  position: absolute;
  top: 2;
  right: 20;
  font-family: open-sans;
}

.payment-cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}

.card-logo {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 5px;
  width: 35px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
}

.card-visa {
  background-image: url(https://www.tatrabanka.sk/web/images/logo_visa.gif);
}

.card-electron {
  background-image: url(https://www.tatrabanka.sk/web/images/logo_visa_electron.gif);
}

.card-mc {
  background-image: url(https://www.tatrabanka.sk/web/images/logo_mc.gif);
}

.card-dc {
  background-image: url(https://www.tatrabanka.sk/web/images/logo_DC.jpg);
}

.card-maestro {
  background-image: url(https://www.tatrabanka.sk/web/images/logo_maestro.gif);
}

.card-discover {
  background-image: url(https://www.tatrabanka.sk/web/images/logo_discover.jpg);
}

.submit-button {
  display: block;
  margin: 0 auto;
  width: 250px;
  font-size: 16px;
}

.main-heading-wrapper {
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  color: white;
  padding: 2rem 0;
  text-align: center;
  margin-top: calc(50vh - 13rem);
  position: relative;
}

.main-heading-wrapper h1 {
  font-size: 2.27rem;
  margin: 0;
}

@media screen and (max-width: 510px) {
  .main-heading-wrapper h1 {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 410px) {
  .main-heading-wrapper h1 {
    font-size: 1.4rem;
  }
}

.main-heading-wrapper h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.8rem;
  font-weight: 100;
  text-transform: uppercase;
  margin: 0;
}

@media screen and (max-width: 410px) {
  .main-heading-wrapper h3 {
    font-size: 0.8rem;
  }
}

.Jesus-img {
  position: absolute;
  top: -250%;
  right: 15%;
  height: 35rem;
}

@media screen and (max-width: 1399px) {
  .main-heading-wrapper {
    z-index: 50;
  }
}

@media screen and (max-width: 869px) {
  .Jesus-img {
    height: 30rem;
  }
}

@media screen and (max-width: 730px) {
  .Jesus-img {
    height: 25rem;
    top: -190%;
    right: 5%;
  }
}

@media screen and (min-width: 1840px) {
  .Jesus-img {
    right: 20%;
  }
}

@media screen and (min-width: 2150px) {
  .Jesus-img {
    right: 26%;
  }
}

.about-project {
  padding: 3rem 0;
  background-color: #f2ebe2;
}

.about-project h4 {
  font-size: 1.466rem;
  margin: 0;
  font-style: italic;
  text-align: center;
  color: #9b8c7d;
}

.about-project h2 {
  font-size: 2.8rem;
  margin: 0;
  text-align: center;
  color: #a36d40;
}

.about-project p {
  color: #9b8c7d !important;
  text-align: center;
}

.small-container {
  width: 90%;
  max-width: 53rem;
  margin: 0 auto;
}

.project-description {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
}

.project-description .video-wrapper {
  padding: 0 1rem;
}

@media screen and (max-width: 799px) {
  .project-description {
    flex-direction: column-reverse;
    margin-bottom: 1rem;
  }

  .project-description .video-wrapper {
    width: 100%;
    padding: 1rem 0;
  }
}

.project-description > div {
  flex: 33.33%;
}

.project-description p {
  text-align: left;
}

.timeline {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.timeline-item {
  display: flex;
  align-items: center;
}

.timeline-item h5 {
  font-size: 1.2rem;
  color: #a36d40;
  font-style: italic;
  text-align: center;
  margin-top: 0;
}

.timeline-item h4 {
  font-size: 1.466rem;
  font-style: normal;
  text-align: center;
}

.timeline-item > div {
  width: 85.72px;
  border-right: 2px solid #dcd3c9;
  padding: 1rem 1rem 1rem 0;
}

.timeline-item > p {
  flex: 3;
  padding-left: 1rem;
}

@media screen and (max-width: 799px) {
  .timeline {
    padding: 0;
  }

  .timeline-item > div {
    flex: 1;
    padding: 0;
  }

  .timeline-item > p {
    flex: 3;
    padding-left: 1rem;
  }
}

.painting-description {
  display: flex;
}

.painting-description > img {
  min-width: 535px;
  flex: 3;
  margin: 0 1rem;
}

.painting-description > div {
  flex: 2;
}

.painting-description h5,
.saints-description h5,
.mobile-description h5 {
  font-size: 1.07rem;
  color: #a36d40;
  margin: 0;
}

.painting-description p,
.saints-description p,
.mobile-description p {
  font-size: 0.94rem;
  color: #9b8c7d !important;
  margin: 0;
}

.pointer {
  height: 10rem;
}

.pointer-right {
  margin-right: 1rem;
  transform: rotate(180deg);
}

.pointer-left {
  margin-left: 1rem;
}

.pointer-down {
  transform: rotate(90deg);
  display: block;
  margin: -2rem auto;
}

.pointer-up {
  transform: rotate(-90deg);
  display: block;
  margin: -2rem auto;
}

.description-item {
  display: flex;
  align-items: center;
}

.painting-description .description-item {
  height: 20rem;
}

.saints-description {
  display: flex;
  padding-bottom: 5rem;
}

.saints-description > img {
  flex: 2;
  min-width: 20px;
}

.saints-description > div {
  flex: 3;
  margin: 0 1rem;
}

@media screen and (max-width: 1399px) {
  .painting-description,
  .saints-description {
    display: none;
  }
}

.mobile-description {
  text-align: center;
  max-width: 535px;
  margin: 3rem auto;
}

@media screen and (min-width: 1400px) {
  .mobile-description {
    display: none;
  }
}

.form-section {
  background-color: #f2ebe2;
  padding: 4rem 0;
}

.form-section h1 {
  font-size: 1.733rem;
  color: #a36d40;
  text-align: center;
  margin: 0;
}

.form-section h2 {
  font-size: 1.6rem;
  color: #9b8c7d;
  font-style: italic;
  text-align: center;
  margin-top: 1rem;
}

.form-section p {
  font-size: 0.94rem;
  color: #9b8c7d !important;
  margin: 3rem auto;
  text-align: center;
  max-width: 30rem;
}
